import { useMemo, useCallback, useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { AnimateKeyframes } from 'react-simple-animate';

import "../App.css";

import "../font/CornucopiaOfOrnamentsFour.ttf"
import "../font/HeroesLegendHollow.ttf"
import "../font/Yokelvision.otf"

import Canevas from '../shared/Canevas';
import { tracerRectangle, tracerLigne } from '../shared/CanevasDessins';
import { nullOuIndefini, validerFonction } from '../shared/Validations';

function Cartouche({morceau, ajouterALaListeDeLecture}) 
{
    // states
    const [canevasContexte, setCanevasContexte] = useState(null);   // contexte du canevas dans lequel on dessine le cartouche
    const [largeurTitre, setLargeurTitre] = useState(0);            // largeur du libellé du titre du morceau
    const [largeurAuteur, setLargeurAuteur] = useState(0);
    const [animer, setAnimer] = useState(false);

    // memos
    // libellés du titre et de l'auteur
    const libelleTitre = useMemo(() => '"' + morceau.titre + '"', [ morceau ]);
    const libelleAuteur = useMemo(() => morceau.auteur, [ morceau ]);

    // dimensions de la cartouche
    const hauteurCartouche = useMemo(() => 100, []);                    // hauteur du composant (pixels)
    const largeurCartouche = useMemo(() => window.innerWidth / 4, []);  // et sa largeur (pixels)
    const gouttiereHaut = useMemo(() => 5, []);                         // gouttières verticales et horizontales du composant (zone vide)
    const gouttiereGauche = useMemo(() => 10, []);
    const gouttiereAuteur = useMemo(() => 50, []);                      // parties gauches et droites tronquées par les traits rosés pour le libellé de l'auteur

    const taillePoliceParDefaut = useMemo(() => parseFloat(getComputedStyle(document.documentElement).fontSize), []);

    // comportement du curseur au survol, selon que l'on a ou pas une fonction de rappel dans les props pour réagir au clic
    const curseur = useMemo(() => validerFonction(ajouterALaListeDeLecture) ? "pointer" : "default", [ ajouterALaListeDeLecture ]);

    // callbacks
    // capteur d'événement au clic sur un cartouche : on déclenche l'ajout du morceau dans la file d'attente
    const actionClick = useCallback(() =>
    {
        if (validerFonction(ajouterALaListeDeLecture))
        {
            ajouterALaListeDeLecture(morceau.id);
        }
    }, [ ajouterALaListeDeLecture, morceau ]);

    // méthode de tracé dans le canevas du cartouche pour dessiner  les lignes rosées qui encadrent le nom de l'auteur
    const dessiner = useCallback((contexte) => 
    {
        // on mémorise dans un state le contexte du canevas
        setCanevasContexte(contexte);
        
        // pour dessiner il faut un contexte
        if (nullOuIndefini(contexte))
        {
            return;
        }
        
        // si la fonction de remise à zéro est définie on l'exécute
        if (validerFonction(contexte.reset)) 
        {
            contexte.reset();
        }

        // on ajoute l'encadrement rouge orangé de l'auteur
        // d'abord un 1er trait centré à gauche, deux traits obliques à 45° vers le haut et vers le base
        const couleurLignes = "#E88284";

        tracerLigne(contexte, 10, 60, 40, 60, couleurLignes, 10);
        tracerLigne(contexte, 40, 60, 60, 40, couleurLignes, 10);
        tracerLigne(contexte, 40, 60, 60, 80, couleurLignes, 10);
        
        // deux traits horizontaux au dessus et en dessous de l'auteur
        tracerLigne(contexte, 58, 40, largeurCartouche - 60, 40, couleurLignes, 10);
        tracerLigne(contexte, 58, 80, largeurCartouche - 60, 80, couleurLignes, 10);

        // puis deux autres traits obliques à droite et un dernier vertical pour fermer le dessin
        tracerLigne(contexte, largeurCartouche - 62, 40, largeurCartouche - 40, 60, couleurLignes, 10);
        tracerLigne(contexte, largeurCartouche - 62, 80, largeurCartouche - 40, 60, couleurLignes, 10);
        tracerLigne(contexte, largeurCartouche - 40, 60, largeurCartouche - 10, 60, couleurLignes, 10);
        
        // on trace le fond en rectangle avec bords arrondis et un ombrage intérieur gauche / haut
        tracerRectangle(
            contexte, 
            gouttiereGauche, gouttiereHaut, largeurCartouche - 2 * gouttiereGauche, hauteurCartouche - 2 * gouttiereHaut, "#F7F6F2", 
            gouttiereGauche, gouttiereHaut, "#804D47"
        );

    }, [ hauteurCartouche, largeurCartouche, gouttiereGauche, gouttiereHaut ]);

    // FX
    // initialisation des largeurs de libellés à afficher
    useEffect(() => {
        // pour effectuer les opérations d'initialisation il faut le contexte du canevas 
        if (canevasContexte == null) {
            return;
        }

        // sauvegarde du contexte avant modification
        canevasContexte.save();

        // on calcule la largeur du texte du titre
        canevasContexte.font = (1.5 * taillePoliceParDefaut) + "px PoliceTitre";
        canevasContexte.textBaseline = "top";
        canevasContexte.textAlign = "center";
        let metriques = canevasContexte.measureText(libelleTitre);
        const largeurTitre = metriques.width;

        // idem pour l'auteur, mais en ajoutant les largeur des symboles de gauche et de droite (en 1,5em), 
        // séparés de chaque coté par des espaces de largeur 2em (paddings), soit au total 4em
        canevasContexte.font = (0.7 * taillePoliceParDefaut) + "px PoliceAuteur";
        metriques = canevasContexte.measureText(libelleAuteur);

        canevasContexte.font = (1.5 * taillePoliceParDefaut) + "px PoliceSymboles";
        const metriquesSymbolesGauche = canevasContexte.measureText(morceau.symbolesGauche);
        const metriquesSymbolesDroite = canevasContexte.measureText(morceau.symbolesDroite);
        const largeurAuteur = metriques.width + metriquesSymbolesGauche.width + metriquesSymbolesDroite.width + (4 * taillePoliceParDefaut);
        
        // on mémorise ces largeurs dans les states
        setLargeurTitre(largeurTitre);
        setLargeurAuteur(largeurAuteur);

        // retour au contexte initial
        canevasContexte.restore();

    }, [canevasContexte, libelleTitre, libelleAuteur, taillePoliceParDefaut, morceau]);

    // indique si le libellé du titre ou de l'auteur est trop grand pour être affiché en entier. Sinon on utilise les défileurs
    const utiliserDefileurPourTitre = largeurTitre >= (largeurCartouche - 2 * gouttiereGauche);
    const utiliserDefileurPourAuteur = largeurAuteur >= (largeurCartouche - 2 * (gouttiereGauche + gouttiereAuteur));

    // capteurs d'événéments pour démarrer ou arrêter les défilements du titre ou de l'auteur
    const demarrerDefilement = useCallback(() => { setAnimer(true);}, []);
    const arreterDefilement = useCallback(() => { setAnimer(false);}, []);

    //TODO: !!!!! bug affichage dans la liste de lecture et dans la liste d'attente !!!!!

    // JSX du composant
    return (        
        <div onMouseEnter={demarrerDefilement} onMouseLeave={arreterDefilement} >
            <Box onClick={actionClick} sx={{ height: "100px", cursor: curseur }}>
                {/* Canevas dans lequel sont dessinées le cadre du fond et les lignes rosées qui encadrent l'auteur */}
                <Canevas dessiner={dessiner} hauteur={hauteurCartouche} largeur={largeurCartouche} sx={{ zIndex:100 }}/>
                {/* Libellé du titre du morceau */}
                <Box className={utiliserDefileurPourTitre ? "defileurParentTitre" : ""} sx={{zIndex:200, position:"relative", top: "-100px", cursor: curseur}} align="center">
                    {utiliserDefileurPourTitre && animer ?
                    <AnimateKeyframes
                        play={true}
                        duration={largeurTitre / 120}
                        iterationCount="infinite"
                        keyframes={[
                            {0: "margin-left:0px"},
                            {99.99: "margin-left:-" + (largeurTitre / 0.9999) + "px"},
                            {100: "margin-left:0px"},
                        ]}
                    >
                        <Typography display="inline" component="span" sx={{ cursor: curseur }} className="defileur titres" >
                            {libelleTitre}&nbsp;&nbsp;{libelleTitre}
                        </Typography>
                    </AnimateKeyframes>
                    :
                    <Typography display="inline" component="span" sx={{ cursor: curseur }} className="defileur titres" >
                        {libelleTitre}
                    </Typography>
                    }
                </Box>
                {/* Libellé de l'auteur du morceau */}
                <Box className={utiliserDefileurPourAuteur ? "defileurParentAuteur" : ""} sx={{zIndex:300, position:"relative", top: "-93px", cursor: curseur}} align="center">
                    {utiliserDefileurPourAuteur && animer ?
                    <AnimateKeyframes 
                        play={true} 
                        duration={largeurAuteur / 120} 
                        iterationCount="infinite" 
                        keyframes={[
                            {0: "margin-left:0px"},
                            {99.99: "margin-left:-" + (largeurAuteur / 0.9999) + "px"},
                            {100: "margin-left:0px"},
                        ]}
                    >
                        <Typography display="inline" component="span" sx={{ cursor: curseur }} className="defileur auteurs" >
                            <Typography display="inline" component="span" sx={{ paddingRight: "2em" }} className="symboles" align="right" >
                                {morceau.symbolesGauche}
                            </Typography>
                            {libelleAuteur}
                            <Typography display="inline" component="span" sx={{ paddingLeft: "2em", paddingRight: "2em" }} className="symboles" align="left" >
                                {morceau.symbolesDroite}
                            </Typography>
                            <Typography display="inline" component="span" sx={{ paddingRight: "2em" }} className="symboles" align="right" >
                                {morceau.symbolesGauche}
                            </Typography>
                            {libelleAuteur}
                            <Typography display="inline" component="span" sx={{ paddingLeft: "2em" }} className="symboles" align="left" >
                                {morceau.symbolesDroite}
                            </Typography>
                        </Typography>
                    </AnimateKeyframes>
                    :
                    <Typography display="inline" component="span" sx={{ cursor: curseur }} className="defileur auteurs" >
                        <Typography display="inline" component="span" sx={{ paddingRight: "2em" }} className="defileur symboles" align="right" >
                            {morceau.symbolesGauche}
                        </Typography>
                        {libelleAuteur}
                        <Typography display="inline" component="span" sx={{ paddingLeft: "2em" }} className="defileur symboles" align="left" >
                            {morceau.symbolesDroite}
                        </Typography>
                    </Typography>
                    }
                </Box>
            </Box>
        </div>
    );
}

export default Cartouche;